<template>
    <ValidationObserver ref="signUpForm">
        <div class="container scope">
            <section class="py-5">
                <div class="mb-5">
                    <p style="font-weight: bold; max-width: 800px"
                       class="mt-2 text-white text-center m-auto"
                       data-aos="fade-in"
                       data-aos-duration="2400">
                        {{ t("website.signupInfo") }}
                    </p>
                </div>
                <b-col cols="12">
                    <b-row>
                        <b-col
                            cols="12"
                            md="6"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                        >
                            <EKInputText
                                class="d-block w-lg-75 mx-auto sign-up"
                                v-model="websiteAccountDto.fullName"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: t(
                                            'accounts.validation.fullName'
                                        )
                                    }
                                ]"
                                :placeholder="t('accounts.fullName')"
                                name="fullName"
                            />
                        </b-col>
                        <b-col
                            cols="12"
                            md="6"
                            data-aos="fade-up"
                            data-aos-duration="2000"
                        >
                            <EKInputText
                                class="d-block w-lg-75 mx-auto sign-up"
                                style="border-radius: 2px;"
                                v-model="websiteAccountDto.email"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'الإيميل مطلوب'
                                    },
                                    {
                                        type: 'email',
                                        message: t('accounts.validation.email')
                                    }
                                ]"
                                :placeholder="t('accounts.email')"
                                name="email"
                            />
                        </b-col>
                        <b-col
                            cols="12"
                            md="6"
                            data-aos="fade-up"
                            data-aos-duration="3000"
                        >
                            <EKInputText
                                class="d-block w-lg-75 mx-auto sign-up"
                                style="border-radius: 2px;"
                                v-model="websiteAccountDto.password"
                                type="password"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: t(
                                            'accounts.validation.password'
                                        )
                                    }
                                ]"
                                :placeholder="t('accounts.password')"
                                name="password"
                            />
                        </b-col>
                        <b-col
                            cols="12"
                            md="6"
                            data-aos="fade-up"
                            data-aos-duration="2000"
                        >
                            <EKInputText
                                class="d-block w-lg-75 mx-auto sign-up"
                                style="border-radius: 2px;"
                                v-model="websiteAccountDto.confirmPassword"
                                type="password"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: t(
                                            'accounts.validation.confirmPassword'
                                        )
                                    }
                                ]"
                                :placeholder="t('accounts.confirmPassword')"
                                name="confirmPassword"
                            />
                        </b-col>
                        <b-col
                            cols="12"
                            md="6"
                            data-aos="fade-up"
                            data-aos-duration="3000"
                        >
                            <EKInputText
                                class="d-block w-lg-75 mx-auto sign-up"
                                style="border-radius: 2px;"
                                v-model="websiteAccountDto.mobileNumber"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: t(
                                            'accounts.validation.mobileNumber'
                                        )
                                    },
                                    {
                                        type: 'mobile',
                                        message: t(
                                            'accounts.validation.validMobile'
                                        )
                                    }
                                ]"
                                :placeholder="t('accounts.mobileNumber')"
                                name="mobileNumber"
                            />
                        </b-col>

                        <b-col
                            cols="12"
                            md="6"
                            data-aos="fade-up"
                            data-aos-duration="2000"
                        >
                            <EKInputSelect
                                fieldClass="text-light"
                                :textLabel="
                                    $store.state.app.lang == 0
                                        ? 'arabicName'
                                        : 'name'
                                "
                                class="d-block w-lg-75 mx-auto mt-2 sign-up country-select"
                                style="border-radius: 2px;"
                                :placeholder="t('accounts.country')"
                                :options="countries"
                                name="select"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: t(
                                            'accounts.validation.country'
                                        )
                                    }
                                ]"
                                v-model="websiteAccountDto.countryId"
                            />
                        </b-col>

                        <b-col
                            cols="12"
                            lg="6"
                            class="mr-lg-1"
                            data-aos="fade-up"
                            data-aos-duration="3000"
                        >
                            <EKInputText
                                class="d-block w-lg-75 mx-auto sign-up"
                                v-model="websiteAccountDto.address"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: t(
                                            'accounts.validation.address'
                                        )
                                    }
                                ]"
                                :placeholder="t('accounts.address')"
                                name="address"
                            />
                        </b-col>
                        <b-col cols="12" lg="6" class="mt-1">
                            <div
                                class="d-flex w-lg-75 justify-content-center mx-auto sign-up text-left"
                                data-aos="fade-up"
                                data-aos-duration="3000"
                            >
                                <b-form-checkbox
                                    @input="changeRulesAgreement"
                                    class="w-100"
                                    name="is-rtl"
                                >
                                    {{ t("accounts.agreementPolicy") }}
                                    <router-link to="/">
                                        {{ t("accounts.policy") }}
                                    </router-link>
                                </b-form-checkbox>
                            </div>
                        </b-col>
                    </b-row>
                    <div class="button-border rounded mb-5 text-center">
                        <b-button
                            data-aos="fade-up"
                            data-aos-duration="3000"
                            variant="flat-secondary"
                            class="custom-btn"
                            @click="createAccount()"
                        >
                            <img src="/media/images/site-images/btn-bg.svg" />
                            <span>
                                {{ t("accounts.signUp") }}
                            </span>
                        </b-button>
                    </div>
                </b-col>
            </section>
        </div>
    </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import { mapActions, mapState, mapMutations } from "vuex";
import EKInputSelect from "@Ekcore/components/EK-forms/EK-input-select";
import Swal from "sweetalert2";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
    components: {
        ValidationObserver,
        EKInputText,
        EKInputSelect
    },
    computed: {
        ...mapState({
            websiteAccountDto: state => state.websiteAccounts.websiteAccountDto,
            rulesAgree: state => state.websiteAccounts.rulesAgree,
            countries: state => state.global.countries
        })
    },

    setup() {
        const { t } = useI18nUtils();
        return { t };
    },
    beforeDestroy() {
        window.removeEventListener("keypress", this.enterSubmit);
    },
    created() {
        this.Reset_Website_Account_Dto();
        window.addEventListener("keypress", this.enterSubmit);
    },
    methods: {
        ...mapActions(["signUp"]),
        ...mapMutations([
            "Reverse_Rules_Agreement",
            "Reset_Website_Account_Dto"
        ]),

        enterSubmit({ key, code }) {
            if (key == "Enter" || code == "Enter") {
                this.createAccount();
            }
        },

        changeRulesAgreement(e) {
            this.Reverse_Rules_Agreement(e);
        },
        createAccount() {
            this.$refs.signUpForm.validate().then(success => {
                if (
                    this.websiteAccountDto.password !=
                    this.websiteAccountDto.confirmPassword
                ) {
                    Swal.fire({
                        title: "كلمة السر غير متطابقة",
                        text: "كلمة السر غير متطابقة",
                        icon: "warning",
                        showConfirmButton: false,
                        timer: 2000
                    });
                    return;
                }
                if (this.rulesAgree == false) {
                    Swal.fire({
                        title: "سياسة الاستخدام والخصوصية",
                        text:
                            "يرجى الموافقة على سياسة الاستخدام والخصوصية للمتابعة",
                        icon: "warning",
                        showConfirmButton: false,
                        timer: 2000
                    });
                    return;
                }
                if (success) {
                    this.signUp(this.websiteAccountDto);
                    this.$refs.signUpForm.reset();
                    // location.reload();
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.scope ::v-deep .sign-up .form-control {
    padding: 2.5rem;
    background-color: inherit;
    color: white !important;
    border: solid 3px white;

    box-shadow: inset -2px 2px 4px rgba(255, 255, 255, 0.1),
        inset 2px -2px 4px rgba(0, 0, 0, 0.1);
}

.scope ::v-deep .vs__selected-options {
    padding: 0px 8px;
}

.scope ::v-deep .vs__selected {
    color: white !important;
}

.scope ::v-deep .vs__dropdown-toggle {
    padding: 1.2rem;
    border: solid 3px white !important;

    background-color: inherit !important;
}

.scope ::v-deep .vs__search::placeholder {
    color: #b9b9c3 !important;
    opacity: 1;
}

.scope ::v-deep .vs__search {
    border: none;
    color: #fff !important;
}

.scope ::v-deep .v-select[data-v-51960549] {
    border: none;
}

.custom-btn {
    position: relative;
    background: transparent !important;
    padding: 1rem;
    margin-top: 2rem;
    img {
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
    }
    span {
        position: relative;
        z-index: 1;
        color: #fff;
    }
}
.scope ::v-deep .custom-control-label:after {
    border: white solid 2px;
    border-radius: 1.5px;
    background-color: var(--custom-dark);
}

.scope ::v-deep .custom-control-label {
    color: #ffffff;
}

.scope ::v-deep .blue-ball {
    &:after {
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        background: transparent;
        border-radius: 50%;
        position: absolute;
        top: 15px;
        left: 12px;
        transform: translateY(-50%);
        transition: 0.3s;
    }
    &:hover,
    &.active {
        border-color: var(--custom-blue-sky) !important;
        &:after {
            background: radial-gradient(
                31.82% 31.82% at 72.35% 31.06%,
                rgba(255, 255, 255, 0.82) 0%,
                rgba(20, 186, 248, 0) 100%
            ) !important;
        }
        &:before {
            background: var(--custom-blue-sky) !important;
        }
    }
}
.w-lg-75 {
    @media screen and (min-width: 992px) {
        width: 75%;
    }
}
</style>
